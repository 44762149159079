import React, { useEffect, useState } from "react";
import {
  useForm,
  FormProvider,
  UseFormMethods,
  FieldError,
} from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import isString from "lodash/isString";

import { ControlledPhoneInput, PhoneInputState } from "@ui-kit/PhoneInput";
import { ControlledInputField } from "@ui-kit/InputFields";
import Dialog from "@ui-kit/Dialog";
import { useCss } from "./styles";
import { ROUTES } from "@constants";
import Button from "@ui-kit/Button";
import { ReactGAEventWait } from "@helpers/ga";
import { minLength, email } from "@validators";
import { Models } from "@services/api";
import { useUserStore } from "@store/UserStore";
import { useNotificationStore } from "@store/NotificationStore";
import { useAppStore } from "@store/AppStore";
import { ServerError } from "@models/common";
import Typography from "@ui-kit/Typography";
import { expectServerError } from "@helpers/serverError";

type FormFields = {
  email: string;
  confirmEmail: string;
  firstName: string;
  lastName: string;
  phone: Models.Phone;
  addressZip: string;
  password: string;
};

type ViewVariant = "general" | "claim" | "auth_required";

const instanceOfFormFields = (field: string) => {
  return (
    field === "email" ||
    field === "firstName" ||
    field === "lastName" ||
    field === "phone" ||
    field === "addressZip" ||
    field === "password"
  );
};

const isSignUpView = (view: string): view is ViewVariant => {
  return view === "general" || view === "claim" || view === "auth_required";
};

const processErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
) => {
  let errorHandled = false;

  errors.forEach((e) => {
    const paths = e.source.pointer?.split("/");

    if (!paths) {
      return false;
    }

    if (instanceOfFormFields(paths[1])) {
      setError(paths[1], {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
    }
  });

  return errorHandled;
};

const SignUpForm: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [{ realLocation }] = useAppStore();
  const [{ user }, { register }] = useUserStore();
  const [, { setUnknownErrorNotification, setNotification }] =
    useNotificationStore();
  const [view, setView] = useState<ViewVariant>("general");
  const [phoneState, setPhoneState] = useState<PhoneInputState | null>(null);
  const methods = useForm<FormFields>();
  const {
    handleSubmit,
    control,
    errors,
    formState,
    setError,
    setValue,
    reset,
    watch,
    trigger,
  } = methods;
  const css = useCss();
  const query = qs.parse(location.search);

  useEffect(() => {
    ReactGAEventWait({ category: "SignUp", action: "Opened" });

    const toReset: Partial<FormFields> = {};

    if (isSignUpView(query.signUpVariant as string)) {
      setView(query.signUpVariant as ViewVariant);
    }

    if (isString(query.email)) {
      toReset.email = query.email;
    }

    if (isString(query.firstName)) {
      toReset.firstName = query.firstName;
    }

    if (isString(query.lastName)) {
      toReset.lastName = query.lastName;
    }

    if (isString(query.phone) && isString(query.addressCountry)) {
      toReset.phone = {
        country: query.addressCountry as Models.Countries,
        number: query.phone,
      };
    }
    if (isString(query.phone) && !isString(query.addressCountry)) {
      toReset.phone = {
        country: Models.Countries.Us,
        number: query.phone,
      };
    }
    if (isString(query.addressCountry) && !isString(query.phone)) {
      toReset.phone = {
        country: query.addressCountry as Models.Countries,
        number: "",
      };
    }

    if (isString(query.addressZip)) {
      toReset.addressZip = query.addressZip;
    }

    reset(toReset);

    history.replace({
      ...location,
      search: qs.stringify({
        ...query,
        addressZip: undefined,
        email: undefined,
        firstName: undefined,
        lastName: undefined,
        phone: undefined,
      }),
    });
  }, []);

  useEffect(() => {
    if (user?.id) {
      ReactGAEventWait({
        category: "SignUp",
        action: "Finished",
        label: `${user.id}`,
      });
      ReactGAEventWait({
        category: "User",
        action: "SignedIn",
        label: `${user.id}`,
      });

      if (query.returnUrl) {
        history.replace(query.returnUrl as string);
      } else {
        closeModal();
      }
    }
  }, [user?.id]);

  useEffect(() => {
    setValue("addressZip", "");
  }, [phoneState?.[1]?.country]);

  function closeModal() {
    ReactGAEventWait({ category: "SignUp", action: "Closed" });
    history.replace({
      pathname: realLocation.current.pathname,
      search: realLocation.current.search,
    });
  }

  const submitForm = handleSubmit(async (values) => {
    try {
      await register({
        ...values,
        joinedFrom: (query.joinedFrom as Models.UserJoinedFrom) || "",
      });
    } catch (e) {
      const errors = expectServerError(e, setUnknownErrorNotification);

      if (!processErrors(errors, setError)) {
        setNotification({
          message: errors[0].title,
          type: "error",
        });
      }
    }
  });

  const title = view === "claim" ? "Claim facility" : "Sign up";

  const submitLabel = view === "claim" ? "Claim your school" : "Sign up";

  const disclaimer =
    view === "claim"
      ? "Claiming your school is free of charge"
      : "Creating an account is free of charge";

  const terms =
    view === "claim"
      ? "By claiming your school you agree to "
      : 'By clicking "sign up" you agree to';

  return (
    <Dialog
      open
      onClose={view !== "auth_required" ? closeModal : undefined}
      scroll="body"
      protectQuit={view !== "auth_required"}
      title={title}
      data-test="sign-up-dialog"
      tier={Models.SubscriptionTier.Ivy}
    >
      <form data-test="registration-form" css={css.container}>
        <Typography bolded align="center" paragraph>
          {disclaimer}
        </Typography>
        <div>
          <FormProvider {...methods}>
            <div css={css.nameRow}>
              <ControlledInputField
                name="firstName"
                label="First name"
                disabled={formState.isSubmitting}
                required
              />

              <ControlledInputField
                name="lastName"
                label="Last name"
                disabled={formState.isSubmitting}
                required
              />
            </div>

            <ControlledPhoneInput
              error={(errors.phone as FieldError)?.message}
              label="Phone"
              disabled={formState.isSubmitting}
              name="phone"
              control={control}
              onChange={setPhoneState}
            />

            <ControlledInputField
              name="addressZip"
              variant="number"
              label="Zip code"
              maxLength={5}
              disabled={formState.isSubmitting}
              required
            />

            <ControlledInputField
              onChange={() => {
                if (errors.confirmEmail) {
                  void trigger("confirmEmail");
                }
              }}
              name="email"
              label="Email"
              type="email"
              disabled={formState.isSubmitting}
              rules={email()}
            />

            <ControlledInputField
              name="confirmEmail"
              label="Confirm email"
              type="email"
              disabled={formState.isSubmitting}
              onPaste={(event) => {
                setError("confirmEmail", {
                  message: "Paste is not supported. Please type",
                  type: "manual",
                });
                event.preventDefault();
                return;
              }}
              rules={{
                ...email(),
                validate: (value: string) => {
                  return (
                    value.toLowerCase() === watch("email").toLowerCase() ||
                    "Email doesn't match"
                  );
                },
              }}
            />

            <ControlledInputField
              name="password"
              helperText="Password must be at least 6 characters"
              label="Password"
              disabled={formState.isSubmitting}
              inputType="password"
              rules={minLength(6)}
              required
            />
          </FormProvider>
        </div>

        <div css={css.footer}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            loading={formState.isSubmitting}
            data-test="submit-btn"
            onClick={submitForm}
            type="submit"
          >
            {submitLabel}
          </Button>
          <p css={css.footerLink}>
            Have an account?{" "}
            <Link
              to={{
                pathname: ROUTES.LOGIN_FORM,
                search: location.search,
              }}
              data-test="log-in-link"
            >
              Log in
            </Link>
          </p>
          <p css={css.footerLink}>
            {terms}{" "}
            <a href="/terms" target="_blank" data-test="sign-up-link">
              Terms of use
            </a>
          </p>
        </div>
      </form>
    </Dialog>
  );
};

export default SignUpForm;
