import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    alignContent: "space-between",
    display: "grid",
    position: "relative",
    width: "100%",

    [theme.mediaRequests.notMobile]: {
      width: 500,
    },
  }),

  footer: {
    marginTop: 30,
  },

  footerLink: {
    marginTop: 15,
    textAlign: "center",
  },

  nameRow: {
    display: "flex",
    justifyContent: "space-between",

    "& > div": {
      width: "calc(50% - 10px)",
    },
  },
});
